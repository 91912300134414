.services-section {
    padding: 100px 0;
    border-bottom: 1px solid rgb(194, 194, 194);
}
.services-section button {
    align-self: center;
    padding: 20px 40px;
    margin-top: 20px;
}
.service-description {
    width: 700px;
    max-width: 100%;
    margin-bottom: 40px;
}
.services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.service {
    border: 1px solid rgb(170, 170, 170);
    width: 350px;
    padding: 30px;
    margin: 10px;

    display: flex;
}
.service h3 {
    margin-bottom: 20px;
}
.service-icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}
@media (max-width: 980px) {
    .service {
        width: 100%;
    }
    .service h3 {
        font-size: 24px;
    }
    .service-icon {
        width: 40px;
        height: 40px;
    }
}