.about-hero {
    background-image: url(../../../public/images/photo_Ax01.jpg);
    background-size: cover;
    background-position: center;
    height: 500px;
    width: 100%;
    margin-top: 70px;
}
@media (min-width: 1300px) {
    .about-hero {
        background-position: center -200px;
    }
}
.about-hero-layer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 59, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-hero h1 {
    font-size: 80px;
}
.about-hero p {
    width: 700px;
    max-width: 90%;
}

/* About main section */
.about-main-sections {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
}
.about-main-sections button {
    padding: 20px 0;
    margin-top: 50px;
    width: 300px;
}
.about-section-container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(170, 170, 170);
    margin: 10px 0;
}
.image-part {
    background-size: cover;
    background-position: center;
    height: 300px;
}
.info-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
@media (min-width: 900px) {
    .about-section-container {
        flex-direction: row;
    }
    .image-part {
        flex: 1;
        height: 450px;
    }
    .info-part {
        flex: 1;
    }
    .info-part p {
        max-width: 450px;
    }
}
.info-part p {
    margin: 5px 0;
}
.about-photo_0x01 {
    background-image: url(../../../public/images/about/photo_0x01.jpg);
}
.about-photo_0x03 {
    background-image: url(../../../public/images/photo_Ax02.jpg);
}
.about-photo_0x04 {
    background-image: url(../../../public/images/about/photo_0x04.jpg);
}
.about-photo_0x05 {
    background-image: url(../../../public/images/about/photo_0x05.jpg);
}