footer {
    display: flex;
    justify-content: center;
    padding: 40px 0;
}
.footer-container {
    display: flex;
    flex-direction: column;
}
.upper-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(141, 141, 141);
    padding: 30px 0;
}
.upper-footer h2 {
    font-size: 30px;
    font-style: normal;
    margin-left: 20px;
    padding: 5px 20px;
    border-radius: 10px;
}
.bottom-footer {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer-column {
    width: 200px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(168, 168, 168);
    padding-top: 30px;
}
@media (max-width: 650px) {
    .upper-footer {
        flex-direction: column;
    }
    .bottom-footer {
        justify-content: center;
    }
    .footer-column {
        align-items: center; 
        text-align: center;
    }
}
.footer-column h4 {
    font-size: 20px;
    margin-bottom: 20px;
    font-style: italic;
}
.footer-column h5 {
    font-size: 16px;
}
.footer-column p {
    margin-bottom: 20px;
}
.footer-column .link {
    margin: 7px;
}