main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-hero {
    background-image: url('../../../public/images/photo_Ax04.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 700px;
}
@media (max-width: 700px) {
    .home-hero {
        background-position-x: -470px;
    }
}
.hero-description {
    max-width: 100%;
}
.home-hero .button1 {
    width: 300px;
    padding: 20px 0;
}
.home-hero-layer2 {
    height: 100%;
    width: 100%;
    background-color: rgb(17, 24, 94, .6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.home-hero-layer2 h1 {
    font-size: 80px;
    line-height: 80px;
    width: 700px;
    margin-bottom: 20px;
}
@media (max-width: 800px) {
    .home-hero-layer2 h1 {
        font-size: 60px;
        line-height: 60px;
        width: 400px;
        max-width: 100%;
    }
}
.home-hero-layer2 p {
    width: 700px;
    margin-bottom: 50px;
}