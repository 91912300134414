.main-chat-container {
    position: fixed;
    bottom: 5vw;
    right: 5vw;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-chat-container div {
    z-index: 2000;
}
.social-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: .4s ease;
    width: 70px;
}
.main-chat-container img {
    width: 50px;
    height: 50px;
}
.social-container img {
    transition: .4s ease;
    cursor: pointer;
    margin: 3px 0;
}
.social-container img:hover {
    scale: 1.3;
}