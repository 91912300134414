.projects-section {
    margin: 100px 0;
}
.projects-section p {
    width: 700px;
    max-width: 90%;
}
.projects {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.project {
    border: 1px solid rgb(199, 199, 199);
    width: 400px;
    max-width: 100%;
    margin: 10px;
}
.projects-section button {
    align-self: center;
    padding: 20px 40px;
    margin-top: 20px;
}
@media (max-width: 1000px) {
    .project {
        width: calc(50% - 40px);
    }
}
@media (max-width: 700px) {
    .project {
        width: 100%;
    }
}
.project-image {
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.project-image img {
    width: 50px;
    cursor: pointer;
}
.project-image .prev {
    transform: rotate(180deg);
}
.project-image .arrow {
    background-image: url(../../../public/images/icons/Arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: .4s ease;
}
.project-image .arrow:hover {
    background-image: url(../../../public/images/icons/ArrowFilled.png);
}
.project-image div {
    z-index: 0;
    height: 100%;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.project-info h3 {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(156, 156, 156);
}
.project-info h5 {
    margin-top: 20px;
}
.project-info span {
    color: rgb(165, 165, 165);
    text-decoration: line-through;
}
.project-info .button1 {
    align-self: center;
    margin-top: 10px;
}
/* Project 1 Photos */
.p1-photo_0x01 {
    background-image: url(../../../public/images/project1/photo_0x01.jpg);
}
.p1-photo_0x02 {
    background-image: url(../../../public/images/project1/photo_0x02.jpg);
}
.p1-photo_0x03 {
    background-image: url(../../../public/images/project1/photo_0x03.jpg);
}
.p1-photo_0x04 {
    background-image: url(../../../public/images/project1/photo_0x04.jpg);
}
.p1-photo_0x05 {
    background-image: url(../../../public/images/project1/photo_0x05.jpg);
}

/* Project 2 Photos */
.p2-photo_0x01 {
    background-image: url(../../../public/images/project2/photo_0x01.jpg);
}
.p2-photo_0x02 {
    background-image: url(../../../public/images/project2/photo_0x02.jpg);
}
.p2-photo_0x03 {
    background-image: url(../../../public/images/project2/photo_0x03.jpg);
}
.p2-photo_0x04 {
    background-image: url(../../../public/images/project2/photo_0x04.jpg);
}

/* Project 3 Photos */
.p3-photo_0x01 {
    background-image: url(../../../public/images/project3/photo_0x01.jpg);
}
.p3-photo_0x02 {
    background-image: url(../../../public/images/project3/photo_0x02.jpg);
}
.p3-photo_0x03 {
    background-image: url(../../../public/images/project3/photo_0x03.jpg);
}

/* Project 4 Photos */
.p4-photo_0x01 {
    background-image: url(../../../public/images/project4/photo_0x01.jpg);
}
.p4-photo_0x02 {
    background-image: url(../../../public/images/project4/photo_0x02.jpg);
}

/* Project 5 Photos */
.p5-photo_0x01 {
    background-image: url(../../../public/images/project5/photo_0x01.jpg);
}
.p5-photo_0x02 {
    background-image: url(../../../public/images/project5/photo_0x02.jpg);
}
.p5-photo_0x03 {
    background-image: url(../../../public/images/project5/photo_0x03.jpg);
}
.p5-photo_0x04 {
    background-image: url(../../../public/images/project5/photo_0x04.jpg);
}

/* Project 6 Photos */
.p6-photo_0x01 {
    background-image: url(../../../public/images/project6/photo_0x01.jpg);
}
.p6-photo_0x02 {
    background-image: url(../../../public/images/project6/photo_0x02.jpg);
}