* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.link {
  text-decoration: none;
  cursor: pointer;
}

.bc_white {
  background-color: white;
}
.bc_w {
  background-color: rgb(240, 242, 255);
}
.bc_b {
  background-color: rgb(0, 0, 82);
}
.bc_p {
  background-color: #72005B;
}
.bc_r {
  background-color: #B92455;
}
.bc_o1 {
  background-color: #E76849;
}
.bc_o2 {
  background-color: #FCB04A;
}
.bc_y {
 background-color: #F9F871;
}

.c_w {
  color: rgb(240, 242, 255);
}
.c_b {
  color: rgb(0, 4, 27);
}
.c_r {
  color: #B92455;
}