.faq-section {
    border-top: 1px solid lightgray;
    padding: 100px 0;
}
.faq-section h2 {
    font-size: 50px;
    line-height: 40px;
    margin-bottom: 10px;
}
.faq-section .descr {
    margin-bottom: 40px;
}
.question-container {
    border: 1px solid rgb(160, 160, 160);
    margin: 10px 0;
    padding: 20px;
    
    max-width: 700px;
    cursor: pointer;
}
.question-container:hover {
    background-color:aliceblue;
}
.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.question h4 {
    font-weight: 200;
    scale: 2.4;
    transition: .4s ease;
    margin-left: 20px;
}
.question-container p {
    margin-top: 20px;
    overflow: hidden;
    transition: .4s ease;

    display: flex;
    align-items: center;
}