h1 {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 36px;
  font-style: italic;
}
h2 {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 50px;
  font-style: italic;
}
h3 {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 28px;
  font-style: italic;
}
h4 {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 24px;
}
h5 {
  font-family: "Fira Sans Condensed", sans-serif;
  font-size: 20px;
}
p {
  font-size: 16px;
}
.layout {
  width: 1300px;
  max-width: 90%;

  display: flex;
  flex-direction: column;
}
.button1 {
  padding: 10px 0;
  font-size: 24px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s ease;
}
.button1:hover {
  background-color: #72005B;
  cursor: pointer;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}