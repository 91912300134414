.form-big-container {
    margin-top: 30px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgb(0, 0, 0, .6);
}
.exit-form {
    font-size: 30px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 10px;
}
.form-container {
    width: 600px;
    max-width: 90%;
    padding: 0 0 30px 0;
    border-radius: 10px;

    display: flex;
    justify-content: center;

}
.form-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 90%;
}
.form-layout h3 {
    text-align: center;
}
.form-layout img {
    cursor: pointer;
    transition: .4s ease;
    margin: 10px 0;
}
.form-layout img:hover {
    scale: 1.2;
}
.form-container form {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 100%;
    margin-top: 40px;
}
.form-container input {
    padding: 20px 10px;
    margin: 10px 0;
}
.form-container button {
    align-self: center;
    margin-top: 30px;
    width: 100%;
}