.services-hero-main {
    height: 570px;
    width: 100%;

    background-image: url(../../../public/images/photo_Ax02.jpg);
    background-size: cover;
    background-position: center;
}
.services-hero-container {
    height: 100%;
    width: 100%;

    background-color: rgba(0, 11, 48, 0.5);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.services-hero-container h1 {
    font-size: 80px;
}
.services-hero-container p {
    width: 700px;
    max-width: 90%;
}
.services-main-container {
    display: flex;
    justify-content: center;
}