.main-hero-projects {
    width: 100vw;
    height: 570px;
    background-image: url(../../../public/images/photo_Ax03.jpg);
    background-size: cover;
}
.hero-projects-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: rgb(0, 0, 0, .4);
}
.hero-projects-container h1 {
    margin-top: 70px;
    font-size: 80px;
}
.hero-projects-container p {
    width: 700px;
    max-width: 90%;
}
.projects-main {
    display: flex;
    justify-content: center;
}
.projects-page .projects-section {
    margin-top: 0;    
}