header {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid rgb(180, 180, 180);
    z-index: 1000;
}
.header {
    top: 0;
    height: 70px;
    width: 1400px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-nav {
    display: flex;
    align-items: center;
}
.header-nav .link {
    height: 70px;
    padding: 0px 20px;
    font-weight: bold;
    border-bottom: 5px solid none;

    display: flex;
    align-items: center;
    transition: .4s ease;
}
.header-nav .link:hover {
    background-color: #e2e2ff;
    border-bottom: 5px solid #B92455;
}
.header-logo h4 {
    font-size: 30px;
    margin-left: 20px;
    border: 1px solid white;
    padding: 5px 20px;
    border-radius: 10px;
}
.menu {
    width: 30px;
    margin: 0 20px;
    display: none;
    cursor: pointer;
}
.hidden-nav-container {
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    transition: .5s ease;
}
.hidden-nav {
    height: 100%;
    width: 300px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 1px solid#cacaca;
}
.hidden-nav .link {
    padding: 30px 0;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    transition: .4s ease;
    background-color: #222288;
    border-top: 3px solid #000052;
}
.hidden-nav .link:hover {
    background-color: rgb(68, 68, 173);
}
.hidden-nav .link:active {
    background-color: rgb(181, 181, 223);
    color: black;
}
@media (max-width: 800px) {
    .header-nav .link {
        display: none;
    }
    .menu {
        display: block;
    } 
}